import React, { FC, useEffect, useRef } from 'react';
import Img from 'gatsby-image';
import { Row } from '../../row/Row';
import { Container } from '../../container/Container';
import { useIntl } from 'gatsby-plugin-intl';
import { SplitText } from '../../../utils/SplitText';
import gsap from 'gsap';

import style from './Slide.scss';


interface ISlideProps {
  sources: any[];
  isCurrentSlide: (index: number) => boolean;
  index: number;
  frontmatter: any;
}

const Slide: FC<ISlideProps> = (props) => {
  const {
    isCurrentSlide,
    index,
    sources,
    frontmatter,
  } = props;


  const intl = useIntl();
  const paragraphRef = useRef(null);
  const cover = useRef(null);

  useEffect(() => {
    if (paragraphRef.current.childNodes.length === 1) {
      const words2 = new SplitText(paragraphRef.current, { type: 'lines', linesClass: 'lineParent' });
      const { words } = new SplitText(paragraphRef.current, { type: 'words', wordsClass: 'lineChild' });
      gsap.set(words, { y: 15, autoAlpha: 0 });
    }
  }, [paragraphRef]);

  return (
    <article className={style(style.slide, { active: isCurrentSlide(index) })}>
      <Img
        fluid={sources}
        style={{ height: '100%', opacity: 0, visibility: 'hidden' }}
        loading={'eager'}
      />
      <div className={style.slide__cover} ref={cover} />
      <div className={style.slide__titleWrapper}>
        <Row>
          <Container>
            <p className={style.slide__title} ref={paragraphRef}>
              {intl.formatMessage({ id: frontmatter.heroText })}
            </p>
          </Container>
        </Row>
      </div>

    </article>
  );

};
export default Slide;
