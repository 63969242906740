import React, { FC, useEffect, useRef } from 'react';
import { useAppDispatchContext, useAppStateContext } from '../../../context/appContext';
import { Link } from 'gatsby';
import ArrowIcon from '../../../assets/svg/arrow.svg';
import gsap, { Bounce } from 'gsap';


import style from './ArrowButton.scss';

interface IArrowButtonProps {
  goToAnchor: () => void;
}

const ArrowButton: FC<IArrowButtonProps> = (props) => {
  const {
    goToAnchor,
  } = props;

  const { cursorStyles } = useAppStateContext();
  const dispatch = useAppDispatchContext();
  const arrow = useRef(null);

  const handleCursorAction = (cursorType) => {

    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  const animateArrow = () => {
    const tl = gsap.timeline();
    tl.fromTo(arrow.current,
      {
        y: 0,
        ease: Bounce.easeInOut,
      },
      {
        duration: 1.3,
        repeat: -1,
        yoyo: true,
        y: 20,
      });
  };

  useEffect(() => {
    animateArrow();
  }, [arrow]);

  return (
    <Link to={'/'} className={style.intro__arrow}>
      <div
        onMouseOver={() => handleCursorAction('action')}
        onMouseLeave={handleCursorAction}
        ref={arrow}
        onClick={goToAnchor}
      >
        <ArrowIcon className={style.intro__arrowSvg} />
      </div>
    </Link>
  );
};

export default ArrowButton;
