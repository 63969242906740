import React, { FC } from 'react';
import { Button } from '../../button/Button';
import { useAppDispatchContext, useAppStateContext } from '../../../context/appContext';
import { useIntl } from 'gatsby-plugin-intl';

interface IAllCollectionsButtonProps {
  goToAnchor: () => void;
}

const AllCollectionsButton: FC<IAllCollectionsButtonProps> = (props) => {
  const {
    goToAnchor,
  } = props;

  const intl = useIntl();
  const { cursorStyles } = useAppStateContext();
  const dispatch = useAppDispatchContext();

  const handleCursorAction = (cursorType) => {

    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  return (
    <Button
      href={'/'}
      color={'white'}
      onMouseOver={() => handleCursorAction('action')}
      onMouseLeave={handleCursorAction}
    >
      <span onClick={goToAnchor}>{intl.formatMessage({ id: 'allCollectionIntro' })}</span>

    </Button>
  );
};

export default AllCollectionsButton;
