import React, { FC } from 'react';

import style from './Line.scss';

interface ILineProps {
  isCurrentSlide: (index: number) => boolean;
  index: number;
}

const Line: FC<ILineProps> = (props) => {
  const {
    isCurrentSlide,
    index,
  } = props;
  
  return (
    <div
      key={index}
      className={style(style.line, { active: isCurrentSlide(index) })}
    />

  );
};

export default Line;
