import React, { useEffect, useRef } from 'react';
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { AnimationWrapper } from '../animationWrapper/AnimationWrapper';
import { useAppDispatchContext } from '../../context/appContext';

import s from './Name.scss';

interface IName {
  path: string;
  title: string;
  isActive: boolean;
  isDefault: boolean;
  hoveredPath: any;
  correspondingGallery: any;
  allGalleries: any;

  setCurrentNode(): void;

  showGallery(): void;

  hideGallery(): void;

  setIsNameClicked(value: boolean): void;

}

interface ICursorType {

}

export const Name: React.FC<IName> =
  ({
     path,
     title,
     isDefault,
     setIsNameClicked,
     correspondingGallery,
   }) => {
    const dispatch = useAppDispatchContext();
    gsap.registerPlugin(ScrollTrigger);

    const animateNameFadeInOut = () => {

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: nameRef.current,
          scrub: 1,
          toggleActions: 'play none reverse none',
        },
      });

      tl.fromTo(nameRef.current, {
          autoAlpha: 0,
          delay: 1,
        },
        {
          autoAlpha: 1,
        }).to(nameRef.current,
        {
          autoAlpha: 0,
          delay: .6,
        },
      );
    };

    const onCursor = (cursorType: ICursorType) => {
      cursorType = cursorType ? cursorType : false;
      dispatch({ type: 'CURSOR_TYPE', cursorType });
    };

    const nameRef = useRef(null);

    const animateNameFill = () => {
      onCursor('collectionHomeHover');
      showMiniatures(correspondingGallery);
    };

    const resetCollection = () => {
      onCursor();
      hideMiniatures(correspondingGallery);
    };

    let timeline = gsap.timeline();

    const showMiniatures = (target) => {
      timeline.fromTo(
        target,
        {
          y: 8,
          autoAlpha: 0,
          immediateRender: false,
        },
        {
          ease: 'power2.out',
          y: 0,
          autoAlpha: .9,
          duration: 1,
        });
    };

    const hideMiniatures = (target) => {
      timeline.kill();
      timeline = gsap.timeline();
      timeline.to(target,
        {
          y: 20,
          autoAlpha: 0,
          ease: Power3.easeOut,
        },
      );
    };

    useEffect(() => {
      animateNameFadeInOut();
    });

    return (
      <AnimationWrapper path={path} onMouseEnter={animateNameFill} onMouseLeave={resetCollection}>
        <li className={s(s.name,
          { default: isDefault },
        )}
            data-text={title.replace(/ /g, '\u00a0')}
            onClick={() => {
              timeline.kill();
              setIsNameClicked(true);
              onCursor();
            }}
            ref={nameRef}
        >
          {title}
        </li>

      </AnimationWrapper>
    );
  };

export default React.memo(Name);
