import React, { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { useQueryCollections } from '../../hooks/use-query-collections';
import Name from './Name';
import { Segment } from '../segment/Segment';
import { CollectionsHelper } from './helpers';
import { Image } from './Image';
import { useIntl } from 'gatsby-plugin-intl';

import s from './Collections.scss';
import { gsap } from 'gsap';

export const Collections: React.FC = () => {
  const intl = useIntl();
  const collectionsGalleryWrapper = useRef(null);

  const { nodes } = useQueryCollections();
  const [selectedCollection, setCollection] = useState<string>('');
  const [currentNode, setCurrentNode] = useState([]);
  const [isNameClicked, setIsNameClicked] = useState<boolean>(false);


  const isCollectionSet = () => {
    if (currentNode.length !== 0) {
      return true;
    } else {
      return false;
    }
  };


  return (
    <Segment>
      <section className={s(s.collections, 'fadeIn')} id={'collections-anchor'}>
        <p className={s.collections__text}>{intl.formatMessage({ id: 'collectionsText' })}</p>
        <ul className={s.collections__list}>
          {
            nodes.map(({ frontmatter }: any, i: number) => {
                return (
                  <Name
                    isDefault={CollectionsHelper.isCollectionEmpty(selectedCollection)}
                    path={frontmatter.path}
                    title={frontmatter.title}
                    key={uuid()}
                    setIsNameClicked={setIsNameClicked}
                    correspondingGallery={collectionsGalleryWrapper.current?.children[i]}
                    allGalleries={collectionsGalleryWrapper.current?.children}
                  />
                );
              },
            )
          }
        </ul>

        <ul className={s.collections__gallery} ref={collectionsGalleryWrapper}>
          {nodes.map(({ frontmatter }, i: number) => {

            gsap.set(collectionsGalleryWrapper.current?.children[i], { autoAlpha: 0 });

            return (
              <Image selected={selectedCollection}
                     miniatures={frontmatter.miniatures}
                     key={i}
              />);
          })}
        </ul>

        {(isCollectionSet() && !isNameClicked)}

      </section>
    </Segment>
  );
};
