import { TimelineLite, Power3 } from 'gsap';
import React, { useEffect, useRef } from 'react';
import Img from 'gatsby-image';

import s from './Image.scss';

interface IMiniature {
  cover: any;
  height: string;
  width: string;
  top: string;
  left: string;
}

interface IImage {
  isActive?: boolean;
  miniatures: IMiniature[];
  currentName: any;

  onCursor(): void;

  hoveredPath: any;
}

export const Image: React.FC<IImage> =
  ({
     isActive,
     miniatures,
     currentName,
   }) => {

    const imgsRef = useRef([]);
    
    return (
      <li ref={imgsRef} className={s(s.image, { active: isActive })}>
        {miniatures.map(({ top, left, width, height, cover }, i) => {
          const styles = {
            'position': 'absolute',
            'top': top + '%',
            'left': left + '%',
            'width': width,
            'height': height,
          };
          return (
            <Img fixed={cover.childImageSharp.fixed} className={s.image} style={styles} key={i} />
          );
        })}
      </li>
    );
  }
;
