import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Intro } from 'components/intro/Intro';
import { Collections } from '../components/collections/Collections';
import { AboutUsHome } from '../components/about-us-home/AboutUsHome';
import SEO from '../components/seo/SEO';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query CollectionsSlidesQuery {
        allMarkdownRemark(filter: { frontmatter: { language: { eq: "en" } } }, sort: { order: ASC, fields: [frontmatter___id] }) {
        nodes {
          frontmatter {
            id
            language
            heroText
            featured {
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 811, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 286) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            featuredMobile {
              childImageSharp {
                fluid(maxWidth: 375) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }  
      }
    }
    `,
  );

  return (
    <>
      <SEO />
      <Intro images={allMarkdownRemark} />
      <Collections />
      <AboutUsHome />
    </>
  );

}
