import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Slide from './Slide/Slide';
import Line from './Line/Line';
import Thumbnail from './Thumbnail/Thumbnail';
import gsap, { Power3 } from 'gsap';
import { ScrollToPlugin, TweenLite } from 'gsap/all';
import { SplitText } from '../../utils/SplitText';
import AllCollectionsButton from './AllCollectionsButton/AllCollectionsButton';
import ArrowButton from './ArrowButton/ArrowButton';
import { useCurtainDispatchContext, useCurtainStateContext } from '../../context/curtainContext';
import { useIntl } from 'gatsby-plugin-intl';

import style from './Intro.scss';

export const Intro: FC = ({ images }) => {

  gsap.registerPlugin(SplitText);

  const [slideIndex, setSlideIndex] = useState(0);

  const linesWrapper = useRef<HTMLDivElement>(null);
  const lines = linesWrapper.current?.children;
  const slidesWrapper = useRef<HTMLDivElement>(null);
  const thumbsWrapper = useRef(null);
  const ctaRef = useRef(null);
  const nextCollectionLabel = useRef(null);
  const arrowRef = useRef(null);
  let isAnimating = false;

  const { isInitial } = useCurtainStateContext();
  const intl = useIntl();


  const isCurrentSlide = (index: number): boolean => index === slideIndex;

  const isCurrentThumbnail = (index: number): boolean => {
    if (index < images.nodes.length) {
      if (slideIndex === images.nodes.length - 1 && index === 0) {
        return true;
      } else if (index === slideIndex + 1) {
        return true;
      }
    }
    return false;
  };

  const getCurrentThumbnailIndex = () => {
    if (slideIndex === images.nodes.length - 1) {
      return 1;
    } else {
      return slideIndex + 2;
    }
  };

  const activateNextLine = (index: number) => {
    deactivateCurrentLine(index);
    const tl = gsap.timeline();
    if (typeof lines !== `undefined`) {
      tl.to(lines[index + 1], { width: '100%' });
    }
  };

  const deactivateCurrentLine = (index: number) => {
    const tl = gsap.timeline();
    if (typeof lines !== `undefined`) {
      if (index === 0) {
        tl.to(lines[images.nodes.length], { width: '17px' });
      } else {
        tl.to(lines[index], { width: '17px' });
      }
    }
  };

  const activateCurrentImage = () => {
    const image = slidesWrapper.current?.children[slideIndex].children[0];
    const tl = gsap.timeline();
    if (typeof image !== `undefined`) {
      isAnimating = true;
      tl.to(image, {
        autoAlpha: 1,
        scale: 1.1,
        duration: 2,
        ease: 'power2.Out',
        onComplete: () => isAnimating = false,
      });
    }
  };

  const deactivateCurrentImage = (index: number) => {
    const image = slidesWrapper.current?.children[slideIndex].children[0];
    const tl = gsap.timeline();
    if (typeof image !== `undefined`) {
      tl.to(image, {
        autoAlpha: 0,
        scale: 1.05,
        duration: .4,
        ease: 'slow',
        onComplete: () => {
          setSlideIndex(index);
        },
      });
    }
  };

  const handleThumbnailClick = () => {
    if (!isAnimating) {
      isAnimating = true;
      if (slideIndex === images.nodes.length - 1) {
        deactivateCurrentImage(0);
        animateTitleOut();
        animateThumbnailOut();
        activateNextLine(0);
      } else {
        deactivateCurrentImage(slideIndex + 1);
        animateTitleOut();
        animateThumbnailOut();
        activateNextLine(slideIndex + 1);
      }
    }
  };

  const animateTitleIn = () => {
    const title = slidesWrapper.current?.children[slideIndex]
      .children[2]
      .children[0]
      .children[0]
      .children[0];

    let singleWords = [];

    for (let i = 0; i < title.children.length; i++) {
      singleWords = [...singleWords, ...title.children[i].children];
    }

    const tl = gsap.timeline();
    const INTERVAL = .15;

    if (!isInitial) {
      tl.fromTo(singleWords, {
        y: 100, autoAlpha: 0, rotation: 3,
      }, {
        duration: .8,
        y: 0,
        autoAlpha: 1,
        ease: 'slow',
        startAt: { rotation: 3 },
        rotation: 0,
        stagger: INTERVAL,
        transformOrigin: 'left bottom',
      });
    }
  };

  const animateTitleOut = () => {
    const title = slidesWrapper.current?.children[slideIndex]
      .children[2]
      .children[0]
      .children[0]
      .children[0];

    let singleWords = [];

    for (let i = 0; i < title.children.length; i++) {
      singleWords = [...singleWords, ...title.children[i].children];
    }
    const tl = gsap.timeline();

    tl.to(singleWords, {
      duration: .4,
      y: -55,
      autoAlpha: 0,
      ease: 'slow',
      startAt: { rotation: 0 },
      rotation: -3,
      transformOrigin: 'left bottom',
    });

  };

  const animateThumbnailOut = () => {
    const thumb = thumbsWrapper.current?.children[getCurrentThumbnailIndex()];
    const tl = gsap.timeline();

    tl.to(thumb, { x: -300, autoAlpha: 0, duration: .5 });
  };

  const animateThumbnailIn = () => {
    const thumb = thumbsWrapper.current?.children[getCurrentThumbnailIndex()];

    const tl = gsap.timeline();
    if (isInitial) {
      gsap.set(thumb, { x: 300, autoAlpha: 0 });
    } else {
      tl.fromTo(thumb, { x: 300, autoAlpha: 0 }, { x: 0, autoAlpha: 1 });
    }

  };

  const gotoAnchor = () => {

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const anchor = document.getElementById('collections-anchor');
      const posTop = anchor && anchor.offsetTop;
      gsap.registerPlugin(ScrollToPlugin);

      TweenLite.to(window, 1, { scrollTo: { y: posTop }, ease: Power3.easeOut });
    }
  };

  const animateAllCollectionsButtonAndArrowIn = () => {

    const button = ctaRef.current?.children[0].children[0];
    const span = button.children[0];
    const arrow = ctaRef.current?.children[1].children[0];

    const tl = gsap.timeline();

    if (isInitial) {
      gsap.set(button, { autoAlpha: 0, scaleX: 0, transformOrigin: 'left' });
      gsap.set(arrow, { autoAlpha: 0, transformOrigin: 'top' });
      gsap.set(span, { autoAlpha: 0 });
    } else {
      tl
        .to(button, { autoAlpha: 1, scaleX: 1, duration: .8, delay: 1.4 }).addLabel('arrowIn')
        .to(span, { autoAlpha: 1, delay: .4, duration: 1 })
        .to(arrow, { autoAlpha: 1, duration: 1 }, 'arrowIn');
    }
  };

  const animateIn = () => {
    animateTitleIn();
    animateThumbnailIn();
    activateCurrentImage();
  };

  const animateLinesInitially = () => {
    const tl = gsap.timeline();
    if (isInitial) {
      gsap.set(linesWrapper.current?.children, { transformOrigin: 'left', scaleX: 0 });
    } else {
      tl.to(linesWrapper.current?.children, { scaleX: 1, delay: 0.5, stagger: .3 });
    }
  };

  const animateNextCollectionLabelInitially = () => {
    const tl = gsap.timeline();

    if (isInitial) {
      gsap.set(nextCollectionLabel.current, { autoAlpha: 0 });
    } else {
      tl.to(nextCollectionLabel.current, { autoAlpha: 1 });
    }
  };

  const animateInitial = () => {
    animateIn();
    animateAllCollectionsButtonAndArrowIn();
    animateLinesInitially();
    animateNextCollectionLabelInitially();
    postponeInitialSlideVisibility();
  };

  const postponeInitialSlideVisibility = () => {
    const slide = slidesWrapper.current?.children[0];
    const tl = gsap.timeline();

    if (isInitial) {
      gsap.set(slide, { autoAlpha: 0 });
      tl.to(slide, { autoAlpha: 1, delay: 1.5 });
    }
  };

  useEffect(() => {
    animateInitial();
  }, [isInitial, slideIndex]);

  return (
    <section className={style.introWrapper}>
      <div className={style.intro} ref={slidesWrapper}>
        {images.nodes.map(({ frontmatter }, index) => {

          const sources = [
            frontmatter.featuredMobile.childImageSharp.fluid,
            {
              ...frontmatter.featured.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ];
          return (
            <Slide
              sources={sources}
              key={index}
              isCurrentSlide={isCurrentSlide}
              index={index}
              frontmatter={frontmatter}
            />
          );
        })}

        <div className={style.navigationWrapper}>

          <div className={style.navigation} ref={linesWrapper}>
            <span className={style.navigation__name}>
              {intl.formatMessage({ id: 'introCollection2020' })}
            </span>
            {images.nodes.map((element, index) => (
              <Line isCurrentSlide={isCurrentSlide} index={index} key={index} />
            ))}
          </div>

          <div className={style.thumbs} ref={thumbsWrapper}>
            <span className={style.thumbs__name} ref={nextCollectionLabel}>
              {intl.formatMessage({ id: 'nextCollection' })}
            </span>
            {images.nodes.map((image, index) => (
              <Thumbnail
                handleThumbnailClick={handleThumbnailClick}
                isCurrentThumbnail={isCurrentThumbnail}
                image={image}
                index={index}
                key={index}
              />
            ))}
          </div>

        </div>

        <div className={style.intro__cta} ref={ctaRef}>
          <AllCollectionsButton goToAnchor={gotoAnchor} />
          <ArrowButton goToAnchor={gotoAnchor} />

        </div>


      </div>
    </section>
  );
};
