import React, { useEffect, useRef } from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { gsap, ScrollTrigger } from 'gsap/all';

import { SmallText } from '../about-us/smallText/SmallText';
import { Container } from '../container/Container';
import { Button } from '../button/Button';
import { FormWrapper } from '../formWrapper/FormWrapper';
import { Form } from '../form/Form';

import s from './aboutUsHome.scss';

export const AboutUsHome = () => {
  const refWrapper = useRef<HTMLDivElement>(null);

  const intl = useIntl();

  const firstTextBlock = {
    header: [
      intl.formatMessage({ id: 'firstTextBlockHeader1' }),
      intl.formatMessage({ id: 'firstTextBlockHeader2' }),
    ],
    content: intl.formatMessage({ id: 'firstTextBlockContent1' }) +
      intl.formatMessage({ id: 'firstTextBlockContent2' }) +
      intl.formatMessage({ id: 'firstTextBlockContent3' }) +
      intl.formatMessage({ id: 'firstTextBlockContent4' }),
  };

  useEffect(() => {
    if (refWrapper.current) {

      gsap.registerPlugin(ScrollTrigger);

      gsap.set(refWrapper.current, {
        autoAlpha: 0,
      });

      gsap.to(refWrapper.current, {
        autoAlpha: 1,
        duration: 2,
        scrollTrigger: {
          trigger: refWrapper.current,
          start: '0 70%',
          once: true,
        },
      });
      return () => {
        gsap.killTweensOf(refWrapper.current);
      };
    }
  }, [refWrapper.current]);

  return (
    <IntlContextConsumer>
      {({ language: lang }) => (
        <div className={s.aboutUsHome} ref={refWrapper}>
          <Container>
            <div className={s.aboutUsHome__text}>
              <SmallText header={firstTextBlock.header} content={firstTextBlock.content} />
            </div>
            <div className={s.aboutUsHome__button}>
              <Button href={'/about-us'} color={'pearl'}>
                {intl.formatMessage({ id: 'aboutUsButtonHome' })}
              </Button>
            </div>
            <FormWrapper child={
              <Form
                topText={intl.formatMessage({ id: 'firstHeader' })}
                secondText={intl.formatMessage({ id: 'secondHeaderAboutUsHome' })}
                lang={lang}
              />}
            />
          </Container>
        </div>
      )}
    </IntlContextConsumer>
  );
};
