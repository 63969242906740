import React from 'react';
import uuid from 'react-uuid';
import s from './smallText.scss';
import { Container } from '../../container/Container';

export const SmallText = ({header, content, whiteAccent }) => {
  
  const returnSingleRow = (collection) => {
   return collection.map((singleRow) => {
      return (
        <div key={uuid()}>
          {singleRow}
        </div>
      )
    })
  };

  return (
    <div className={s.smallText}>
        <h5 className={s(s.smallText__header, { whiteAccent: whiteAccent })}>
          { returnSingleRow(header) }
        </h5>
        <p className={s(s.smallText__content, { whiteAccent: whiteAccent })}>
          { content }
        </p>
    </div>
  );
};
