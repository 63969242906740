import React, { FC, useEffect, useRef } from 'react';
import Img from 'gatsby-image';
import { useAppDispatchContext, useAppStateContext } from '../../../context/appContext';
import gsap from 'gsap';
import { useCurtainStateContext } from '../../../context/curtainContext';

import style from './Thumbnail.scss';

interface IThumbnailProps {
  isCurrentThumbnail: (index: number) => boolean;
  image: any;
  index: number;
  handleThumbnailClick: () => void;
}

const Thumbnail: FC<IThumbnailProps> = (props) => {
  const {
    isCurrentThumbnail,
    image,
    index,
    handleThumbnailClick,
  } = props;

  const { cursorStyles } = useAppStateContext();
  const { isInitial } = useCurtainStateContext();
  const dispatch = useAppDispatchContext();
  const thumb = useRef(null);

  const handleCursorAction = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  useEffect(() => {
    if (isInitial) {
      gsap.set(thumb.current, { x: 300, autoAlpha: 0 });
    }
  });

  return (
    <div
      key={index}
      onClick={handleThumbnailClick}
      onMouseOver={() => handleCursorAction('action')}
      onMouseLeave={handleCursorAction}
      className={style.thumb__wrapper}
      ref={thumb}
    >
      <Img
        className={style(style.thumb, { active: isCurrentThumbnail(index) })}
        fixed={image.frontmatter.featured.childImageSharp.fixed}
        draggable={false}
        style={{ position: 'absolute' }}
      />
    </div>
  );
};

export default Thumbnail;
